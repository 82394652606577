<template>
  <div class="flex justify-center pt-3">
    <img alt="High Maintenance Logo" src="./assets/logo.png" />
  </div>
  <HomePage />
</template>

<script>
import HomePage from "./components/HomePage.vue";

export default {
  name: "App",
  components: {
    HomePage,
  },
};
</script>
