<script>
import WhatsappIcon from "@/components/Icons/WhatsappIcon.vue";

export default {
  name: "HomePage",
  components: {
    WhatsappIcon,
  },
};
</script>

<template>
  <head>
    <!-- Set the HTML page title -->
    <title>High Maintenance - Marijuana Club - Cape St. Francis</title>
  </head>
  <div class="container mx-auto px-4 md:max-w-xl lg:max-w-2xl">
    <header class="text-center my-12">
      <h1 class="text-5xl font-bold text-green-600">High Maintenance</h1>
      <h2 class="text-3xl text-green-500">Private Members Cannabis Club</h2>
      <h3 class="text-xl text-green-400">St. Francis Bay, South Africa</h3>
      <p class="mt-4 text-gray-700 max-w-2xl mx-auto">
        Elevate Your Senses with exclusive access to premium cannabis selections
        in a luxurious, private setting.
      </p>
    </header>

    <nav class="flex flex-wrap justify-center space-x-4 my-4">
      <a href="#about" class="text-green-600 hover:text-green-800">About Us</a>
      <a href="#membership" class="text-green-600 hover:text-green-800"
        >Membership</a
      >
      <a href="#menu" class="text-green-600 hover:text-green-800">Menu</a>
      <a href="#events" class="text-green-600 hover:text-green-800">Events</a>
      <a href="#contact" class="text-green-600 hover:text-green-800">Contact</a>
    </nav>

    <div class="text-center my-6">
      <a
        href="https://scanzy.co/public/QKEn5/user-registration"
        class="px-6 py-3 bg-green-600 text-white font-bold rounded-full hover:bg-green-700 transition duration-300 mr-1"
      >
        Join Club
      </a>
      <a
        href="https://scanzy.co/shop/QKEn5"
        class="px-6 py-3 bg-green-600 text-white font-bold rounded-full hover:bg-green-700 transition duration-300"
      >
        Shop Now
      </a>
    </div>

    <main>
      <section id="about" class="my-8">
        <h4 class="text-2xl text-green-600 font-bold mb-3">
          About High Maintenance
        </h4>
        <p class="text-gray-700">
          Welcome to High Maintenance – where the grass is always greener. Join
          us for an elevated experience in the heart of St. Francis Bay. Our
          club offers a serene and sophisticated space for connoisseurs and
          cannabis enthusiasts alike.
        </p>
        <!-- Add more about content here -->
      </section>

      <section id="membership" class="my-8">
        <h4 class="text-2xl text-green-600 font-bold mb-3">Membership Perks</h4>
        <p class="text-gray-700">
          Become part of an exclusive circle where you can relax, enjoy, and
          indulge in the finest cannabis. Membership includes access to private
          lounges, expert consultations, and members-only events.
        </p>
        <!-- Add more membership content here -->
      </section>

      <section id="menu" class="my-8">
        <h4 class="text-2xl text-green-600 font-bold mb-3">Our Menu</h4>
        <p class="text-gray-700">
          Explore our extensive menu of premium cannabis products, from classic
          strains to exotic new blends, all curated for your pleasure.
        </p>
        <!-- Add menu items here -->
      </section>

      <section id="events" class="my-8">
        <h4 class="text-2xl text-green-600 font-bold mb-3">Events</h4>
        <p class="text-gray-700">
          Join us for weekly events that celebrate cannabis culture, including
          tastings, guest speakers, and social mixers.
        </p>
        <!-- Add events content here -->
      </section>

      <section id="contact" class="my-8">
        <h4 class="text-2xl text-green-600 font-bold mb-3">Get in Touch</h4>
        <p class="text-gray-700 mb-3">
          Have questions or want to join? Contact us and let's get you started
          on a high note.
        </p>
        <p>
          <a
            href="tel:0833693124"
            class="flex items-center justify-center text-green-600 text-2xl"
          >
            <whatsapp-icon style="height: 32px" class="mr-2 text-green-600" />
            083 369 3124</a
          >
        </p>
        <!-- Add contact form or information here -->
      </section>

      <section class="map my-8">
        <h4 class="text-2xl text-green-600 font-bold mb-3">Find Us</h4>
        <!-- Embed Google Map -->
        <div class="mapouter">
          <div class="gmap_canvas">
            <!-- Replace with actual Google Maps embed iframe -->
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3300.4962348011572!2d24.849708575722257!3d-34.18479537310736!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzTCsDExJzA1LjMiUyAyNMKwNTEnMDguMiJF!5e0!3m2!1sen!2sza!4v1699349410577!5m2!1sen!2sza"
              width="600"
              height="450"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </section>

      <section class="seo-content my-8">
        <h4 class="text-2xl text-green-600 font-bold mb-3">
          Discover Cannabis Culture
        </h4>
        <p class="text-gray-700">
          At High Maintenance, we're not just a club; we're a community. Dive
          into articles, guides, and the latest news in the world of cannabis
          right here.
        </p>
        <!-- Add keyword-rich SEO content here -->
      </section>
    </main>

    <footer class="text-center my-8">
      <p class="text-gray-700">
        &copy; High Maintenance. All rights reserved. | Where every bud is a new
        friend.
      </p>
      <!-- Add footer content here -->
    </footer>
  </div>
</template>

<style scoped>
@media (max-width: 640px) {
  /* Adjust this media query to target your specific mobile breakpoint, if needed */
  .container {
    max-width: 100% !important; /* This overrides max-width set on larger screens for mobile */
    padding-left: 1rem; /* Adjust as needed for your design */
    padding-right: 1rem; /* Adjust as needed for your design */
  }
}

/* existing styles can remain unchanged */
</style>
