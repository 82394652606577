import { createApp } from "vue";
import App from "./App.vue";

import "./assets/styles/tailwind.css";

// Import the FontAwesomeIcon component and the specific icons you need
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// Add icons to the library
library.add(faUserSecret);

// Create a Vue instance and register the FontAwesomeIcon component globally
const app = createApp(App);

app.component("font-awesome-icon", FontAwesomeIcon);

// Mount the Vue application
app.mount("#app");
